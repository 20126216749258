<template>
  <!-- Conditional tooltip table cell component -->
  <td
    class="text-sm text-on-surface-elevation-2 leading-md align-middle first:pl-20 last:pr-20"
    :class="{
      'px-12 py-16 text-center': aCenter,
      'px-12 py-16 text-right': aRight,
      'px-12 py-16 text-left': aLeft,
      '!p-0 text-center': onlyButton,
      relative: posRelative
    }"
    @click="$emit('click')"
  >
    <template v-if="tdValue && tdValue.length > 0">
      <template v-if="showTooltip">
        <s-tooltip
          arrow
          :content="formattedTooltipContent"
          :duration="0"
          :distance="4"
          useFlip
          flipOnUpdate
          placement="bottom-start"
          trigger="mouseenter focus"
          :theme="'studio-tooltip'"
          :zIndex="2501"
          :allowHTML="true"
        >
          <template #target>
            <span ref="textRef" :class="textClass">
              {{ tdValue }}
            </span>
          </template>
        </s-tooltip>
      </template>
      <span v-else ref="textRef" :class="[textClass]">
        {{ tdValue }}
      </span>
    </template>
    <slot></slot>
  </td>
</template>

<script setup lang="ts">
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';

const props = defineProps<{
  tdValue?: string;
  aRight?: boolean;
  aCenter?: boolean;
  aLeft?: boolean;
  onlyButton?: boolean;
  textClass?: string;
  posRelative?: boolean;
}>();

defineEmits<{
  click: [v: void];
}>();

const textRef = ref<HTMLElement | null>(null);
const isTextTruncated = ref(false);

const formattedTooltipContent = computed(() => {
  if (!props.tdValue) {
    return '';
  }
  return `<div style="max-width: 36rem; word-break: break-word; white-space: normal; overflow-wrap: break-word;">${props.tdValue}</div>`;
});

const checkIfTextIsTruncated = () => {
  if (textRef.value) {
    isTextTruncated.value = textRef.value.scrollWidth > textRef.value.clientWidth;
  }
};

const showTooltip = computed(() => {
  return isTextTruncated.value;
});

onMounted(() => {
  checkIfTextIsTruncated();
});

watch(
  () => props.tdValue,
  () => {
    // Need to wait for DOM update
    nextTick(checkIfTextIsTruncated);
  }
);

// Recheck when window resizes
let resizeObserver: ResizeObserver | null = null;
onMounted(() => {
  if (typeof ResizeObserver !== 'undefined') {
    resizeObserver = new ResizeObserver(() => {
      checkIfTextIsTruncated();
    });

    if (textRef.value) {
      resizeObserver.observe(textRef.value);
    }
  } else {
    window.addEventListener('resize', checkIfTextIsTruncated);
  }
});

onBeforeUnmount(() => {
  if (resizeObserver) {
    resizeObserver.disconnect();
  } else {
    window.removeEventListener('resize', checkIfTextIsTruncated);
  }
});
</script>
